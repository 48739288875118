// Remember:
// Every file in scripts/ acts as an entry-point and gets tranformed to build/. Feel free to create as much as you want.
// Files in subdirectories will be ignored but you can require them in entry-point-files.
// Add an _ to a file and it will just be ignored(good for tests).
// Add your entry-point-files to templates/layout/yourlayout.html


// ===============================================================================
// LIBRARIES
// ===============================================================================


// =require ../../libraries/jquery.3.2.0.min.js
// //=require ../../libraries/jquery-3.3.1.min.js

//=require ../../libraries/lodash.js
//=require ../../libraries/matchMedia.js
//=require ../../libraries/jquery.hoverIntent.js
//=require ../../libraries/elementDirectionAndOffsetByScroll.js
//=require ../../libraries/velocity.1.5.0.min.js
//=require ../../libraries/imagesLoaded.js
//=require ../../libraries/isoptope.3.0.3.min.js
//=require ../../libraries/slick-1.6.0/slick/slick.js
// //=require ../../libraries/sumoselect/jquery.sumoselect.js
//=require ../../libraries/fancybox-2.1.5/jquery.fancybox.js
//=require ../../libraries/cookieconsent2-3.0.3/cookieconsent.min.js
//=require ../../libraries/jquery.matchHeight.js
//=require ../../libraries/jquery-validation/jquery.validate.js





// BOOTSTRAP

// //=require ../../libraries/bootstrap-4.0.0/js/util.js
// //=require ../../libraries/bootstrap-4.0.0/js/alert.js
// //=require ../../libraries/bootstrap-4.0.0/js/button.js
// //=require ../../libraries/bootstrap-4.0.0/js/carousel.js
// //=require ../../libraries/bootstrap-4.0.0/js/collapse.js
// //=require ../../libraries/bootstrap-4.0.0/js/dropdown.js
// //=require ../../libraries/bootstrap-4.0.0/js/index.js
// //=require ../../libraries/bootstrap-4.0.0/js/modal.js
// //=require ../../libraries/bootstrap-4.0.0/js/popover.js
// //=require ../../libraries/bootstrap-4.0.0/js/scrollspy.js
// //=require ../../libraries/bootstrap-4.0.0/js/tab.js
// //=require ../../libraries/bootstrap-4.0.0/js/tooltip.js



